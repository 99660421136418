import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Typography, IconButton, Button, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import axios from 'axios';
import CreateAccount from './CreateAccount';
import Circular from '../components/Circular';
import AttachModal from '../components/AttachModal';
import AttachProxyModal from '../components/AttachProxyModal';
import EditAccountModal from '../components/EditAccountModal';
import { IoEye } from "react-icons/io5";
import { IoIosEyeOff } from "react-icons/io";
import { FcApproval, FcExpired, FcProcess } from "react-icons/fc";

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { FaStop } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai"
import { FaHistory } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import ChangeProxyModal from '../components/ChangeProxyModal';
import HistoryModal from '../components/HistoryModal';
import CategoriesMain from '../components/AccountCategoriesMain';
import { CgMoreVertical } from 'react-icons/cg';
import AccountCategoriesMain from '../components/AccountCategoriesMain';
import { BiCategoryAlt } from 'react-icons/bi';
import AccountAssignCategoryModal from '../components/AccountAssignCategoryModal';
import ChangeGmailModal from '../components/ChangeGmailModal';
import AccountAssignCategoryModalBulk from '../components/AccountAssignCategoryModalBulk';
import { formatTime } from '../utils/formatTime';
import ChangeAccountCategoryModal from '../components/ChangeAccountCategoryModal';
import AccountReviewCategoryAssignModal from '../components/account/AccountReviewCategoryAssignModal';
import { MdAssignmentAdd } from 'react-icons/md';



const AllAccounts = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [attachModalOpen, setAttachModalOpen] = useState(false);
  const [attachProxyModalOpen, setAttachProxyModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedProxy, setSelectedProxy] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // For the menu anchor
  const [currentRow, setCurrentRow] = useState(null); // For storing the row associated with the menu
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEditAccount, setSelectedEditAccount] = useState(null);
  const [changeProxyModalOpen, setChangeProxyModalOpen] = useState(false);
  const [changeGmailModalOpen, setChangeGmailModalOpen] = useState(false);
  const [passSelectedCategoryId, setPassSelectedCategoryId] = useState('all');
  const [assignMultipleToCategory, setAssignMultipleToCategoty] = useState([])

  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedAccountForCategory, setSelectedAccountForCategory] = useState(null)
  const [assignCategoryModalOpen, setAssignCategoryModalOpen] = useState(false)
  const [changeCategoryModalOpen, setChangeCategoryModalOpen] = useState(false)
  const [assignCategoryModalBulkOpen, setAssignCategoryModalBulkOpen] = useState(false)
  const [assignReviewCategoryModalOpen, setAssignReviewCategoryModalOpen] = useState(false)


  const navigate = useNavigate()
  const user = localStorage.getItem("user")
  const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
  useEffect(() => {
    console.log("use effect called")

    if (!user) {
      console.log("Not a user")
      navigate("/login")
    }

  }, [user])

  useEffect(() => {
    axios
      .get(`${baseURL}account/allaccounts`)
      .then((res) => {
        // console.log(res);
        setData(res.data.accounts);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [render]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hours} H, ${minutes} M, ${seconds} S`;
  };

  const columns = useMemo(() => [
    //{ field: '_id', headerName: 'ID', flex: 2 },
    { field: 'profileName', headerName: 'Profile Name', flex: 2 },
    { field: 'region', headerName: 'Region', flex: 1 },
    {
      field: 'lastRun', headerName: 'Last Run', flex: 2,
      renderCell: (params) => {
        const date = new Date(params.value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      }

    },

    {
      field: 'lastruntime', headerName: 'Last Run Time', flex: 2,
      renderCell: (params) => {
        const lastruntime = params.row.lastruntime;
        return (
          <div>
            <Tooltip title={formatTime(lastruntime)}>
              <span>{formatTime(lastruntime)}</span>
            </Tooltip>
          </div>
        );
      }

    },
    { field: 'totalRun', headerName: 'Total Run', flex: 1 },
    {
      field: 'totalruntime', headerName: 'Total Run Time', flex: 2,
      renderCell: (params) => {
        const totalruntime = params.row.totalruntime;
        return (
          <div>
            <span>{formatTime(totalruntime)}</span>
          </div>
        );
      }
    },
    { field: 'Error', headerName: 'Error', flex: 2 },
    {
      field: 'nextPostRunDate', headerName: 'Next R Post Time', flex: 2,
      renderCell: (params) => {
        const date = new Date(params.value);
        return `${date.toLocaleDateString()}`;
      }
    },
    {
      field: 'nextQuestionPostRunDate', headerName: 'Next Q Post Time', flex: 2,
      renderCell: (params) => {
        const date = new Date(params.value);
        return `${date.toLocaleDateString()}`;
      }
    },
    {
      field: 'nextSpecificPostRunDate', headerName: 'Next S-R Post Time', flex: 2,
      renderCell: (params) => {
        const date = new Date(params.value);
        return `${date.toLocaleDateString()}`;
      }
    },
    { field: 'totalCookies', headerName: 'Total Cookies', flex: 1 },
    {

      field: 'isPostRunning',
      headerName: 'R/Post Availability',
      flex: 0.5,
      renderCell: (params) => (
        <div>
          {params.row.isPostRunning ? (
            <div >
              <FcApproval size="1.5rem"
              />
            </div>
          ) : (
            <div >
              <FcExpired size="1.5rem"
              />
            </div>
          )}
        </div>
      ),
    },
    {

      field: 'isSpecificPostRunning',
      headerName: 'S-R/Post Availability',
      flex: 0.5,
      renderCell: (params) => (
        <div>
          {params.row.isSpecificPostRunning ? (
            <div >
              <FcApproval size="1.5rem"
              />
            </div>
          ) : (
            <div >
              <FcExpired size="1.5rem"
              />
            </div>
          )}
        </div>
      ),
    },
    {

      field: 'isQuestionPostRunning',
      headerName: 'Q/Post Availability',
      flex: 0.5,
      renderCell: (params) => (
        <div>
          {params.row.isQuestionPostRunning ? (
            <div >
              <FcApproval size="1.5rem"
              />
            </div>
          ) : (
            <div >
              <FcExpired size="1.5rem"
              />
            </div>
          )}
        </div>
      ),
    },

    {

      field: 'gmail',
      headerName: 'Gmail ID',
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.gmailStatus === 1 ? (
            <Tooltip title={params.row.gmailID}>

              {params.row.gmailID}
            </Tooltip>
          ) : (
            <Button
              style={{ backgroundColor: '#1B3C73', color: "white" }}
              variant="outlined"
              size="small"
              onClick={() => {
                setSelectedAccount(params.row);
                setAttachModalOpen(true);
              }}
            >
              Attach
            </Button>
          )}
        </div>
      ),
    },
    {
      field: 'proxy',
      headerName: 'Proxy ID/PORT',
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.proxyStatus === 1 ? (
            params.row.proxyPort === "" ? params.value : params.row.proxyPort
          ) : (
            <Button
              style={{ backgroundColor: '#1B3C73', color: "white" }}
              variant="outlined"
              size="small"
              onClick={() => {
                setSelectedProxy(params.row);
                setAttachProxyModalOpen(true);
              }}
            >
              Attach
            </Button>
          )}
        </div>
      ),
    },
    {
      field: 'setrunning',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        < div style={{ margin: "auto" }}>

          {params.row?.isProcessing == true ? <>
            <div >
              <FcProcess size="1.5rem"
                color='purple'
              />
            </div>
          </> : <>
            {params.row.running == 'active' ? (
              <div >
                <FaStop size="1.5rem"
                  color='red'
                  onClick={() => handleRunningClick(params.row)}

                />
              </div>) : (
              <div >

                <FaPlay
                  size="1.5rem"
                  color='green'
                  onClick={() => handleRunningClick(params.row)}

                />
              </div>
            )
            }</>
          }
        </div >
      ),
    },
    {
      field: 'options',
      headerName: 'Options',
      flex: 1,
      renderCell: (params) => (

        <IconButton
          aria-label="more"
          onClick={(event) => handleMenuClick(event, params.row)}
        >
          <CgMoreVertical />
        </IconButton>
      ),
    },
  ], []);


  const flattenConnectedUsers = useMemo(() => {
    return data.map((rowM) => ({
      id: rowM._id,
      _id: rowM._id,
      profileName: rowM.profileName,
      region: rowM.region,
      lastRun: rowM.lastRun,
      gmailStatus: rowM.gmailStatus,
      running: rowM.running,
      totalRun: rowM.totalRun,
      totalCookies: rowM.totalCookies,
      proxyPort: rowM.proxyPort,
      gmailID: rowM.gmailID,
      gmail: rowM.gmail,
      proxyStatus: rowM.proxyStatus,
      isProcessing: rowM.isProcessing,
      proxy: rowM.proxy,
      totalruntime: rowM.totalruntime,
      lastruntime: rowM.lastruntime,
      isAssignReviewCategory: rowM.isAssignReviewCategory,
      nextPostRunDate: rowM.nextPostRunDate,
      isPostRunning: rowM.isPostRunning,
      isQuestionPostRunning: rowM.isQuestionPostRunning,
      nextQuestionPostRunDate: rowM.nextQuestionPostRunDate,
      isSpecificPostRunning: rowM.isSpecificPostRunning,
      nextSpecificPostRunDate: rowM.nextSpecificPostRunDate,
      Error: rowM.Error
    }));
  }, [data]);

  const handleOpenMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  // };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleHistoryClick = () => {
    setSelectedAccountId(currentRow._id);
    setHistoryModalOpen(true);
  };

  const handleChangeProxyClick = () => {
    setChangeProxyModalOpen(true);
    handleCloseMenu();
  };

  const handleChangeGmailClick = () => {
    setChangeGmailModalOpen(true);
    handleCloseMenu();
  };

  const handleEdit = () => {
    setSelectedEditAccount(currentRow);
    setEditModalOpen(true);
    handleCloseMenu();
  };

  const handleDelete = () => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}account/delete/${currentRow._id}`)
      .then(() => {
        if (ToasterToggle) { // Check if toaster should be displayed

          toast.success("Account deleted successfully", {
          });
        }
        setRender(!render); // Trigger re-render to update the accounts list
        handleCloseMenu(); // Close the options menu
      })
      .catch((error) => {
        if (ToasterToggle) { // Check if toaster should be displayed

          toast.error("Failed to delete account", {
            // Toast properties
          });
        }
        console.error("Failed to delete account:", error);
      });
  };


  const handleShowURLs = () => {
    // console.log("Show URLs for", currentRow._id);
    // Logic to open the Show URLs modal
    handleCloseMenu();
  };

  const handleAttachSuccess = (attachedAccountId) => {
    console.log(`Gmail attached to Account ${attachedAccountId} successfully`);
    setRender(!render);
  };

  const handleAttachProxySuccess = (attachedAccountId) => {
    console.log(`Proxy attached to Account ${attachedAccountId} successfully`);
    setRender(!render);
  };




  // const handleRunningClick = (account) => {
  //   // Make an API call to set the running state to "active"
  //   axios
  //     .put(`${baseURL}account/setrunning/${account._id}`, {
  //       running: 'active',
  //     })
  //     .then((res) => {
  //       toast.success(`Account ${account._id} is now running.`, {
  //         position:"top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //       setRender(!render);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleRunningClick = (account) => {
    const action = account.running === 'active' ? 'stop' : 'setrunning';
    const newRunningState = account.running === 'active' ? 'inactive' : 'active';

    axios.put(`${baseURL}account/${action}/${account._id}`, { running: newRunningState })
      .then((res) => {
        const actionVerb = newRunningState === 'active' ? 'started' : 'stopped';
        if (ToasterToggle) { // Check if toaster should be displayed

          toast.success(`Account ${account.profileName} (${account._id}) has been ${actionVerb}.`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setRender(prev => !prev); // Trigger re-render to update the accounts list
      })
      .catch((error) => {
        console.error(`Failed to ${newRunningState} account:`, error);
        if (ToasterToggle) { // Check if toaster should be displayed

          toast.error(error?.response?.data?.message || `Failed to ${newRunningState} account.`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  // const handleMenuClick = (event, row) => {
  //   setAnchorEl(event.currentTarget);
  //   setCurrentRow(row);
  // };

  const handleMenuClick = useCallback((event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  }, []);

  const handleAssignCategory = (account) => {
    setSelectedAccountForCategory(account);

    setAssignCategoryModalOpen(true);
    handleMenuClose();
  };

  const handleChangeCategory = (account) => {
    setSelectedAccountForCategory(account);

    setChangeCategoryModalOpen(true);
    handleMenuClose();
  };

  const handleAssignReviewCategory = (account) => {
    setSelectedAccountForCategory(account);

    setAssignReviewCategoryModalOpen(true);
    handleMenuClose();
  };
  const onAssignToCategory = async (Data) => {
    setAssignMultipleToCategoty(Data)
    setAssignCategoryModalBulkOpen(true);
    handleMenuClose();
  };




  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  console.log("ALL ACCOUNTS RENDERED")

  return (
    // <div style={{ width: "100%" }}>
    <div style={{ width: "99%" }}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {attachModalOpen && (
        <AttachModal
          open={attachModalOpen}
          onClose={() => setAttachModalOpen(false)}
          onAttach={handleAttachSuccess}
          selectedRow={selectedAccount}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', color: "white" }}>
        <Typography variant="h5"> Accounts </Typography>
        <CreateAccount render={render} setRender={setRender} passSelectedCategoryId={passSelectedCategoryId} />
      </div>
      {/* <div sx={{ width: "100%" }} > */}
      <div>


        {data.length > 0 ? (
          <>
            <AccountCategoriesMain render={render} setrender={setRender} setPassSelectedCategoryId={setPassSelectedCategoryId} data={data} rows={flattenConnectedUsers} columns={columns} onAssignToProxy={onAssignToCategory} />
          </>
        ) : (
          <>
            <Circular />
          </>
        )}
      </div>

      {attachProxyModalOpen && (
        <AttachProxyModal
          open={attachProxyModalOpen}
          onClose={() => setAttachProxyModalOpen(false)}
          onAttach={handleAttachProxySuccess}
          selectedRow={selectedProxy}
        />
      )}
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleHistoryClick}>
          <ListItemIcon>

            <FaHistory fontSize="large" />
          </ListItemIcon>
          <ListItemText>History</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleChangeProxyClick}>
          <ListItemIcon>
            <FaExchangeAlt fontSize="large" />
          </ListItemIcon>
          <ListItemText>Change Proxy</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleChangeGmailClick}>
          <ListItemIcon>
            <FaExchangeAlt fontSize="large" />
          </ListItemIcon>
          <ListItemText>Change Gmail</ListItemText>
        </MenuItem>

        {currentRow?.isAssignCategory && (
          <MenuItem onClick={() => handleChangeCategory(currentRow)}>
            <ListItemIcon>
              <FaExchangeAlt fontSize="large" />
            </ListItemIcon>
            <ListItemText>Change Category</ListItemText>
          </MenuItem>)}



        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <CiEdit fontSize="large" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        {/* <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <AiTwotoneDelete fontSize="large" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem> */}
        {!currentRow?.isAssignCategory && (
          <MenuItem onClick={() => handleAssignCategory(currentRow)}>
            <ListItemIcon>
              <BiCategoryAlt fontSize="small" />
            </ListItemIcon>
            <ListItemText>Assign Category</ListItemText>
          </MenuItem>)}

        {/* {!currentRow?.isAssignReviewCategory && (
          <MenuItem onClick={() => handleAssignReviewCategory(currentRow)}>
            <ListItemIcon>

              <MdAssignmentAdd fontSize="large" />
            </ListItemIcon>
            <ListItemText>Assign Review Category</ListItemText>
          </MenuItem>)} */}



      </Menu>

      {editModalOpen && (
        <EditAccountModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          accountData={selectedEditAccount}
          render={render}
          setRender={setRender}
        />)}
      {changeProxyModalOpen && (
        <ChangeProxyModal
          open={changeProxyModalOpen}
          onClose={() => setChangeProxyModalOpen(false)}
          account={currentRow}
          setRender={setRender}
        />
      )}
      {changeGmailModalOpen && (
        <ChangeGmailModal
          open={changeGmailModalOpen}
          onClose={() => setChangeGmailModalOpen(false)}
          account={currentRow}
          setRender={setRender}
        />
      )}

      {historyModalOpen && (
        <HistoryModal
          open={historyModalOpen}
          onClose={() => setHistoryModalOpen(false)}
          accountId={selectedAccountId}
        />)}

      {assignCategoryModalOpen && (
        <AccountAssignCategoryModal
          open={assignCategoryModalOpen}
          onClose={() => setAssignCategoryModalOpen(false)}
          account={selectedAccountForCategory}
          setRender={setRender}
          render={render}

        />
      )}

      {changeCategoryModalOpen && (
        <ChangeAccountCategoryModal
          open={changeCategoryModalOpen}
          onClose={() => setChangeCategoryModalOpen(false)}
          account={selectedAccountForCategory}
          setRender={setRender}
          render={render}

        />
      )}

      {assignCategoryModalBulkOpen && (
        <AccountAssignCategoryModalBulk
          open={assignCategoryModalBulkOpen}
          onClose={() => setAssignCategoryModalBulkOpen(false)}
          account={assignMultipleToCategory}
          setRender={setRender}
          render={render}

        />
      )}

      {/* 
      {assignReviewCategoryModalOpen && (
        <AccountReviewCategoryAssignModal
          open={assignReviewCategoryModalOpen}
          onClose={() => setAssignReviewCategoryModalOpen(false)}
          account={selectedAccountForCategory}
          setRender={setRender}
          render={render}
        />
      )} */}
    </div>
  );
};

export default AllAccounts;

