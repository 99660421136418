import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { Button, Typography } from '@mui/material';
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import { CiSquareCheck } from "react-icons/ci";
import { GiToaster } from "react-icons/gi";
import { styled } from '@mui/material/styles';


import { green, grey } from '@mui/material/colors'; // Import color utilities
import SettingCategoriesTable from '../components/SettingCategoryTable';

const baseURL = process.env.REACT_APP_BASE_URL || ''; // Ensure you have a fallback or default value

const AllSetting = () => {
    const [maxTimeInSeconds, setMaxTimeInSeconds] = useState('');
    const [minTimeInSeconds, setMinTimeInSeconds] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [siteRange, setSiteRange] = useState([0, 50]);
    const [reviewRange, setReviewRange] = useState([7, 21]);
    const [questionRange, setQuestionRange] = useState([7, 21]);
    const [specificReviewRange, setSpecificReviewRange] = useState([7, 21]);
    const [LinksCount, setLinksCount] = useState(null);
    const [selectedToaster, setSelectedToaster] = useState(false);
    const [render, setRender] = useState(false)
    const [selectedCategotyId, setSelectedCategoryId] = useState(0)
    const [isImage, setIsImage] = useState(false);
    const [isReviewToggle, setIsReviewToggle] = useState(false)
    const [isQuestionToggle, setIsQuestionToggle] = useState(false)
    const [isSpecificReviewToggle, setIsSpecificReviewToggle] = useState(false)
    const [serverUtcTime, setServerUtcTime] = useState(null); // Store initial UTC time from the server
    const [currentTime, setCurrentTime] = useState(null); // Live updated time

    useEffect(() => {
        fetchAdminSettings();
    }, []);

    // useEffect(() => {
    //     if (serverUtcTime) {
    //         const startTime = new Date(serverUtcTime).getTime();
    //         const timer = setInterval(() => {
    //             const now = new Date(startTime + (Date.now() - startTime)).toISOString();
    //             setCurrentTime(now);
    //         }, 1000);

    //         return () => clearInterval(timer); // Cleanup interval on unmount
    //     }
    // }, [serverUtcTime]);

    useEffect(() => {
        if (serverUtcTime) {
            const timer = setInterval(() => {
                const now = new Date();
                setCurrentTime(now.toLocaleString()); // Use local time directly
            }, 1000);

            return () => clearInterval(timer); // Cleanup on component unmount
        }
    }, [serverUtcTime]);


    const fetchAdminSettings = async () => {
        try {
            const response = await axios.get(`${baseURL}setting/get-admin-setting`);
            const { maxTimeInSeconds, minTimeInSeconds, startTime, toasterToggle, endTime, minSiteRange, maxSiteRange, isImageVideo, reviewToggle, postingTimeout, minReviewRange, maxReviewRange, minQuestionRange, maxQuestionRange, questionToggle, specificReviewToggle, minSpecificReviewRange, maxSpecificReviewRange } = response.data.settings;
            const linksCount = response.data.linksCount
            const utcTimes = response.data.utcTime
            setMaxTimeInSeconds(maxTimeInSeconds);
            setMinTimeInSeconds(minTimeInSeconds);
            setStartTime(startTime);
            setEndTime(endTime);
            setLinksCount(linksCount)
            setSiteRange([minSiteRange, maxSiteRange]);
            setReviewRange([minReviewRange, maxReviewRange]);
            setSelectedToaster(toasterToggle)
            setIsImage(isImageVideo)
            setIsReviewToggle(reviewToggle)
            setQuestionRange([minQuestionRange, maxQuestionRange]);
            setIsSpecificReviewToggle(specificReviewToggle)
            setSpecificReviewRange([minSpecificReviewRange, maxSpecificReviewRange]);
            setIsQuestionToggle(questionToggle)
            setServerUtcTime(utcTimes)
            localStorage.setItem("Toaster", toasterToggle || false)
        } catch (error) {
            console.error('Failed to fetch settings', error);
        }
    };

    const fetchSettings = async (categoryId) => {
        try {
            const response = await axios.get(`${baseURL}setting/category/${categoryId}`);
            const { maxTimeInSeconds, minTimeInSeconds, startTime, endTime, minSiteRange, maxSiteRange, isImageVideo, reviewToggle, postingTimeout, minReviewRange, maxReviewRange, minQuestionRange, maxQuestionRange, questionToggle, specificReviewToggle, minSpecificReviewRange, maxSpecificReviewRange } = response.data.settings;
            const linksCount = response.data.linksCount
            setMaxTimeInSeconds(maxTimeInSeconds);
            setMinTimeInSeconds(minTimeInSeconds);
            setStartTime(startTime);
            setEndTime(endTime);
            setLinksCount(linksCount)
            setSiteRange([minSiteRange, maxSiteRange]);
            setReviewRange([minReviewRange, maxReviewRange]);
            setIsImage(isImageVideo)
            setIsReviewToggle(reviewToggle)
            setQuestionRange([minQuestionRange, maxQuestionRange]);
            setIsQuestionToggle(questionToggle)
            setIsSpecificReviewToggle(specificReviewToggle)
            setSpecificReviewRange([minSpecificReviewRange, maxSpecificReviewRange]);

            // setPostTimeout(postingTimeout)

        } catch (error) {
            console.error('Failed to fetch settings', error);
        }
    };

    const centerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    };
    const handleSiteRange = (event, newValue) => {
        setSiteRange(newValue);
    };

    const handleReviewToggleChange = () => {
        if (isSpecificReviewToggle) {
            toast.warn("You can't turn on both Review and Specific Review at the same time");
            return;
        }
        setIsReviewToggle(!isReviewToggle);
    };

    const handleSpecificReviewToggleChange = () => {
        if (isReviewToggle) {
            toast.warn("You can't turn on both Review and Specific Review at the same time");
            return;
        }
        setIsSpecificReviewToggle(!isSpecificReviewToggle);
    };

    const handleReviewRangeChange = (event, newValue) => {
        setReviewRange(newValue);
    };

    const handleQuestionRangeChange = (event, newValue) => {
        setQuestionRange(newValue);
    };

    const handleSpecificReviewRangeChange = (event, newValue) => {
        setSpecificReviewRange(newValue);
    };

    function siteRangeText(value) {
        return `${value}`;
    }

    const handleCategorySelect = (categoryId) => {
        console.log("CATEGORY ID : ", categoryId)
        setSelectedCategoryId(categoryId);
        fetchSettings(categoryId)
        // setPassSelectedCategoryId(categoryId)
        // if (categoryId === 'all') {
        //     fetchAccounts(categoryId, true);
        // } else {
        //     setSelectedCategoryId(categoryId);
        //     fetchAccounts(categoryId);
        // }
    };

    const handleToaster = async (SelectedToaster) => {
        setSelectedToaster(SelectedToaster)
        try {
            await axios.post(`${baseURL}setting/update-admin`, {
                toasterToggle: SelectedToaster,
            });
            localStorage.setItem("Toaster", SelectedToaster)

            if (SelectedToaster) {
                toast.success("Toaster Setting Successfully Saved");
            }
        } catch (error) {
            console.error('Failed to update settings', error);
        }
    };

    const handleUpdateSettings = async () => {
        try {
            await axios.put(`${baseURL}setting/update/category/${selectedCategotyId}`, {
                maxTimeInSeconds,
                minTimeInSeconds,
                startTime,
                endTime,
                siteRange,
                reviewRange,
                questionRange,
                isImageVideo: isImage,
                reviewToggle: isReviewToggle,
                questionToggle: isQuestionToggle,
                specificReviewToggle: isSpecificReviewToggle,
                specificReviewRange,
            });
            if (selectedToaster) {
                toast.success("Settings Successfully Saved");
            }
        } catch (error) {
            console.error('Failed to update settings', error);
            toast.error("Select a category first");
        }
    };
    // console.log(maxTimeInSeconds, minTimeInSeconds, startTime, endTime, siteRange,LinksCount, "TIMESS")
    return (
        <Box sx={{ flexGrow: 1, color: 'white', mt: 10 }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Box display={'flex'} justifyContent={"space-between"}>
                <Typography variant="h3"> Settings </Typography>
                <Typography variant="h6">
                    Server Time: {currentTime || 'Loading...'}
                </Typography>
                {/* <Typography variant="h6"> Server Time : {currentTime ? formatUtcTime(currentTime) : 'Loading...'}</Typography> */}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2, padding: ".2rem", marginRight: "2rem" }}>
                {/* <Button
                    onClick={() => { handleToaster(!selectedToaster) }
                    }
                    sx={{
                        width: 100,
                        height: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '1rem',
                        bgcolor: selectedToaster ? green[500] : grey[500],
                        '&:hover': {
                            bgcolor: selectedToaster ? green[700] : grey[700],
                        },
                        color: '#fff',
                    }}
                >
                    {selectedToaster ? <GiToaster size={"2.5rem"} /> : <GiToaster size={"2.5rem"} />}
                </Button> */}
                <Typography variant='h5'>Toaster</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, padding: ".2rem" }}>
                    <MaterialUISwitch
                        checked={selectedToaster}
                        onChange={() => handleToaster(!selectedToaster)}
                        inputProps={{ 'aria-label': 'toaster toggle' }}
                    />
                </Box>
            </Box>
            <Box display={"flex"}>
                <SettingCategoriesTable render={render} onCategorySelect={handleCategorySelect} />

                <Grid container spacing={3} sx={{ mt: 5 }}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Min time in seconds"
                            type="number"
                            value={minTimeInSeconds}
                            onChange={(e) => setMinTimeInSeconds(e.target.value)}
                            inputProps={{ style: { color: 'white' } }}
                            sx={{
                                '& .MuiOutlinedInput-root': { '& > fieldset': { borderColor: 'white' } },
                                '& .MuiInputLabel-root': { color: 'white' }, // Ensure label text is also white
                            }}
                        // InputProps={{
                        //     endAdornment: (
                        //         <InputAdornment position="end">
                        //             <IconButton
                        //                 color="primary"
                        //                 onClick={() => console.log('IconButton clicked')}
                        //                 edge="end"
                        //             >
                        //                 <IoCheckmarkDoneCircleSharp />
                        //             </IconButton>
                        //         </InputAdornment>
                        //     ),
                        // }}
                        />



                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Max time in seconds"
                            type="number"
                            value={maxTimeInSeconds}
                            onChange={(e) => setMaxTimeInSeconds(e.target.value)}
                            InputLabelProps={{ style: { color: 'white' } }}
                            inputProps={{ style: { color: 'white' } }}
                            sx={{
                                '& .MuiOutlinedInput-root': { '& > fieldset': { borderColor: 'white' } },
                                '& .MuiInputLabel-root': { color: 'white' }, // Ensure label text is also white
                            }}

                        />
                    </Grid>
                    {/* <Grid item xs={12} >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, padding: "1rem" }}>
                        <span>Toaster Toggle</span>
                        <Button
                            onClick={() => { setSelectedToaster(!selectedToaster) }
                            }
                            sx={{
                                width: 100,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '1rem',
                                bgcolor: selectedToaster ? green[500] : grey[500],
                                '&:hover': {
                                    bgcolor: selectedToaster ? green[700] : grey[700],
                                },
                                color: '#fff',
                            }}
                        >
                            {selectedToaster ? <CiSquareCheck size={"2.5rem"} /> : <CiSquareCheck size={"2.5rem"} />}
                        </Button>
                    </Box>
                </Grid> */}
                    <Grid item xs={12}>
                        <Typography id="range-slider" gutterBottom>
                            Visit Sites Range
                        </Typography>
                        <Box style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <Slider
                                getAriaLabel={() => 'Site Range'}
                                sx={{ width: "85%" }}
                                value={siteRange}
                                onChange={handleSiteRange}
                                valueLabelDisplay="auto"
                                getAriaValueText={siteRangeText}
                                min={0}
                                max={LinksCount}
                            />

                        </Box>
                    </Grid>

                    <Box sx={{
                        flexGrow: 1,
                        p: 2,
                        bgcolor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)', // Apply a background color
                        boxShadow: 3, // Apply box shadow for depth, similar to a card
                        borderRadius: 2, // Rounded corners like a card
                        margin: 'auto', // Center the box
                        m: 1, // Margin top for spacing
                        height: "50vh"
                    }}>
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem" }}>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: 2, padding: ".2rem" }}>
                                <Typography variant="p">Review</Typography>
                                <Switch
                                    checked={isReviewToggle}
                                    onChange={handleReviewToggleChange}
                                    // color="warning"
                                    label="Review"
                                    inputProps={{ 'aria-label': 'toggle review' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: 2, padding: ".2rem" }}>
                                <Typography variant="p">Specific Review</Typography>
                                <Switch
                                    checked={isSpecificReviewToggle}
                                    onChange={handleSpecificReviewToggleChange}
                                    // color="warning"
                                    label="Specific Review"
                                    inputProps={{ 'aria-label': 'toggle Specific Review' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: 2, padding: ".2rem" }}>
                                <Typography variant="p">Question</Typography>
                                <Switch
                                    checked={isQuestionToggle}
                                    onChange={() => setIsQuestionToggle(!isQuestionToggle)}
                                    // color="warning"
                                    label="Question"
                                    inputProps={{ 'aria-label': 'toggle question' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: 2, padding: ".2rem" }}>
                                <Typography variant="p">Image/Video</Typography>
                                <Switch
                                    checked={isImage}
                                    onChange={() => setIsImage(!isImage)}
                                    // color="warning"
                                    label="Image/Video"
                                    inputProps={{ 'aria-label': 'toggle image/video' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: 2, padding: ".2rem", width: "30%" }}>
                                <Typography variant="h6">Review Post Timeout Days</Typography>
                                <Slider
                                    value={reviewRange}
                                    onChange={handleReviewRangeChange}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={30}
                                />

                                <Typography variant="h6">Specific Review Post Timeout Days</Typography>
                                <Slider
                                    value={specificReviewRange}
                                    onChange={handleSpecificReviewRangeChange}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={30}
                                />

                                <Typography variant="h6">Question Post Timeout Days</Typography>
                                <Slider
                                    value={questionRange}
                                    onChange={handleQuestionRangeChange}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={30}
                                />
                            </Box>

                        </Box>

                        <Box style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <Grid item xs={12} sm={6}>
                                <div style={centerStyle} >
                                    <TimePicker onChange={setStartTime} value={startTime} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={centerStyle}>
                                    <TimePicker onChange={setEndTime} value={endTime} />
                                </div>
                            </Grid>
                        </Box>


                        <Box style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>

                            <Button
                                variant="contained"
                                sx={{ mt: 3, py: 1, backgroundColor: 'transparent', color: 'white', ':hover': { backgroundColor: '#1f6291', color: 'white' } }}
                                onClick={handleUpdateSettings}
                                startIcon={<IoCheckmarkDoneCircleSharp />} // Assuming you're using an icon component
                            >Apply Times
                            </Button>
                        </Box>

                    </Box>
                </Grid>
            </Box>
        </Box>
    );
};

export default AllSetting;


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${encodeURIComponent(
                    '#fff',
                )}" height="20" width="20" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14h2v-2h-2v2zm0-4h2V7h-2v5z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: green[600],
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: grey[600],
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${encodeURIComponent(
                '#fff',
            )}" height="20" width="20" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: grey[400],
        borderRadius: 20 / 2,
    },
}));
