import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import CreateAccountCategoryModal from './CreateAccountCategoryModal';
import AccountCategoriesTable from './AccountCategoriesTable';
import DataTable from './DataTable';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import ChangeCategoryName from './ChangeCategoryName';
import SearchAccounts from './SearchAccounts';
import AssignReviewCategoryModalToAccountCategory from './account/AssignReviewCategoryModalToAccountCategory';
import AssignQuestionCategoryModalToAccountCategory from './account/AssignQuestionCategoryModalToAccountCategory';
import AssignSpecificReviewCategoryModalToAccountCategory from './account/AssignSpecificReviewCategoryModalToAccountCategory';


const AccountCategoriesMain = ({ render, setrender, data, rows, columns, setPassSelectedCategoryId, onAssignToProxy }) => {
    const [accountsData, setAccountsData] = useState([]);
    const [categories, setCategories] = useState([]);
    // const [render , setrender] = useState(false)
    const [selectedCategoryId, setSelectedCategoryId] = useState('all');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [delData, setDeleteData] = useState([])
    const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
    const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
    const [delCategoryId, setDelCategoryId] = useState(null);
    const [categoryDetails, setCategoryDetails] = useState({});
    const [currentPage, setCurrentPage] = useState(1);  // For tracking the current page
    const [totalPages, setTotalPages] = useState(1);    // For tracking the total pages
    const [totalItems, setTotalItems] = useState(1);    // For tracking the total pages
    const [isAssignReviewCategoryModalOpen, setIsAssignReviewCategoryModalOpen] = useState(false);
    const [isAssignSpecificReviewCategoryModalOpen, setIsAssignSpecificReviewCategoryModalOpen] = useState(false);

    const [selectedCategoryForReview, setSelectedCategoryForReview] = useState(null);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [isAssignQuestionCategoryModalOpen, setIsAssignQuestionCategoryModalOpen] = useState(false);
    const [selectedCategoryForQuestion, setSelectedCategoryForQuestion] = useState(null);

    // const [renderTableComponent, setRenderTableComponment] = useState(true)

    useEffect(() => {
        setAccountsData(rows)

        fetchCategories();
        loadColumnVisibility()

    }, [render]);

    useEffect(() => {
        handleCategorySelect(selectedCategoryId);
    }, [render]);


    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}category/all`);
            setCategories(response.data);
        } catch (error) {
            if (ToasterToggle) {
                toast.error('Failed to fetch categories');
            }
            console.error('Error fetching categories:', error);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); // Update the current page
        fetchAccounts(selectedCategoryId, newPage); // Fetch new data when the page changes
    };

    const setSearchAccounts = (data) => {
        // console.log("DATA :: ", data)
        setTotalPages(data.totalPages);  // Set the total pages from the API
        setCurrentPage(data.currentPage);  // Set the current page from the API
        setTotalItems(data.totalItems); // Set the total number of items

        const flattenConnectedUsers = data.accounts.map((rowM, index) => {
            return {
                id: rowM._id,
                _id: rowM._id,
                profileName: rowM.profileName,
                region: rowM.region,
                running: rowM.running,
                gmail: rowM.gmail,
                proxyStatus: rowM.proxyStatus,
                totalRun: rowM.totalRun,
                gmailID: rowM.gmailID,
                totalCookies: rowM.totalCookies,
                proxy: rowM.proxy,
                proxyPort: rowM.proxyPort,
                gmailStatus: rowM.gmailStatus,
                isAssignCategory: rowM.isAssignCategory,
                isProcessing: rowM.isProcessing,
                lastRun: rowM.lastRun,
                totalruntime: rowM.totalruntime,
                lastruntime: rowM.lastruntime,
                isAssignReviewCategory: rowM.isAssignReviewCategory,
                nextPostRunDate: rowM.nextPostRunDate,
                isPostRunning: rowM.isPostRunning,
                isQuestionPostRunning: rowM.isQuestionPostRunning,
                nextQuestionPostRunDate: rowM.nextQuestionPostRunDate,
                isSpecificPostRunning: rowM.isSpecificPostRunning,
                nextSpecificPostRunDate: rowM.nextSpecificPostRunDate,
                Error: rowM.Error
            };
        });
        setAccountsData(flattenConnectedUsers);
        // console.log("DATA :: ", data)
    }
    // console.log(accountsData, totalPages, currentPage, totalItems)
    const fetchAccounts = async (categoryId, page = 1, limit = 100) => {
        try {
            // let url = `${process.env.REACT_APP_BASE_URL}account/byCategory/${categoryId}`;
            let url = `${process.env.REACT_APP_BASE_URL}account/byCategory/${categoryId}?page=${page}&limit=${limit}`;

            const response = await axios.get(url);
            // setAccountsData(response.data.accounts);
            setTotalPages(response.data.totalPages);  // Set the total pages from the API
            setCurrentPage(response.data.currentPage);  // Set the current page from the API
            setTotalItems(response.data.totalItems); // Set the total number of items


            const flattenConnectedUsers = response.data.accounts.map((rowM, index) => {
                return {
                    id: rowM._id,
                    _id: rowM._id,
                    profileName: rowM.profileName,
                    region: rowM.region,
                    running: rowM.running,
                    gmail: rowM.gmail,
                    proxyStatus: rowM.proxyStatus,
                    totalRun: rowM.totalRun,
                    gmailID: rowM.gmailID,
                    totalCookies: rowM.totalCookies,
                    proxy: rowM.proxy,
                    proxyPort: rowM.proxyPort,
                    gmailStatus: rowM.gmailStatus,
                    isAssignCategory: rowM.isAssignCategory,
                    isProcessing: rowM.isProcessing,
                    lastRun: rowM.lastRun,
                    totalruntime: rowM.totalruntime,
                    lastruntime: rowM.lastruntime,
                    isAssignReviewCategory: rowM.isAssignReviewCategory,
                    nextPostRunDate: rowM.nextPostRunDate,
                    isPostRunning: rowM.isPostRunning,
                    isQuestionPostRunning: rowM.isQuestionPostRunning,
                    nextQuestionPostRunDate: rowM.nextQuestionPostRunDate,
                    isSpecificPostRunning: rowM.isSpecificPostRunning,
                    nextSpecificPostRunDate: rowM.nextSpecificPostRunDate,
                    Error: rowM.Error
                };
            });

            setAccountsData(flattenConnectedUsers);

        } catch (error) {
            if (ToasterToggle) {
                toast.error('Failed to fetch accounts for the category');
            }
            console.error('Error fetching accounts by category:', error);
        }
    };


    const handleDeleteCategory = async (categoryId) => {
        try {
            console.log("category ID ", categoryId)
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}category/delete/${categoryId}`);
            if (ToasterToggle) {
                toast.success(response.data.message || 'Category deleted successfully');
            }
            setrender(prev => !prev); // Refresh the categories list
        } catch (error) {
            if (ToasterToggle) {
                toast.error(error.response.data.message || 'Failed to delete category');
            } console.error('Error deleting category:', error);
        }
    };

    // const handleEditCategory = async (categoryId) => {
    //     try {
    //         console.log("category ID ", categoryId)
    //         // const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}category/delete/${categoryId}`);
    //         // if (ToasterToggle) {
    //         //     toast.success(response.data.message || 'Category deleted successfully');
    //         // }
    //         // setrender(prev => !prev); // Refresh the categories list
    //     } catch (error) {
    //         if (ToasterToggle) {
    //             toast.error(error.response.data.message || 'Failed to delete category');
    //         } console.error('Error deleting category:', error);
    //     }
    // };


    const ConfirmHandleDelete = async (deleteData) => {
        setIsDeleteModalOpen(true);
        setDeleteData(deleteData)
    }

    const handleDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        handleBulkDelete(delData)
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };

    const ConfirmHandleDeleteCategory = async (deleteData) => {
        setIsDeleteCategoryModalOpen(true);
        setDelCategoryId(deleteData)
    }

    const handleDeleteConfirmCategory = () => {
        setIsDeleteCategoryModalOpen(false);
        handleDeleteCategory(delCategoryId)
    };

    const handleEditCategory = (category) => {
        setCategoryDetails(category)
        setIsEditCategoryModalOpen(true);
    };

    const handleAssignReviewCategory = (category) => {
        setSelectedCategoryForReview(category)
        setIsAssignReviewCategoryModalOpen(true);
    };

    const handleAssignSpecificReviewCategory = (category) => {
        setSelectedCategoryForReview(category)
        setIsAssignSpecificReviewCategoryModalOpen(true);
    };

    const handleDeleteCancelCategory = () => {
        setIsDeleteCategoryModalOpen(false);
    };

    const handleCloseEditCategory = () => {
        setIsEditCategoryModalOpen(false);
    };


    const handleCloseAssignReviewCategory = () => {
        setIsAssignReviewCategoryModalOpen(false);
    };

    const handleCloseAssignSpecificReviewCategory = () => {
        setIsAssignSpecificReviewCategoryModalOpen(false);
    };

    const handleAssignQuestionCategory = (category) => {
        setSelectedCategoryForQuestion(category)
        setIsAssignQuestionCategoryModalOpen(true);
    };

    const handleCloseAssignQuestionCategory = () => {
        setIsAssignQuestionCategoryModalOpen(false);
    };

    const handleBulkDelete = async (deleteData) => {
        // Make a DELETE request with all selected Gmail IDs
        // console.log("DELETE DATA : ", deleteData)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}account/bulkdelete`, { ids: deleteData });
            if (ToasterToggle) {
                toast.success(response?.data?.message || 'Selected Account records deleted successfully');
            } setrender(prev => !prev); // Refresh the Gmail list
        } catch (error) {
            if (ToasterToggle) {
                toast.error(error?.response?.data?.message || 'Failed to delete selected Account records');
            } console.error(error);
        }
    };

    const handleBulkStart = async (startData) => {
        // Make a POST request with all selected account IDs
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}account/bulkStart`, { accountIds: startData });

            // If using a toast notification for success messages
            if (ToasterToggle) { // Assuming you have a mechanism to check if toasters are enabled (e.g., a state or from localStorage)
                toast.success(response.data.message || 'Selected accounts started successfully');
                if (response.data.errors && response.data.errors.length > 0) {
                    // Loop over errors and display a toaster for each
                    response.data.errors.forEach((error) => {
                        toast.error(`${error.message} for account ID: ${error.accountId}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }
            }

            // Optionally, trigger a re-fetch or update the UI to reflect the change
            // setrender(prev => !prev);
            fetchAccounts(selectedCategoryId, currentPage)

        } catch (error) {
            // If using a toast notification for error messages
            if (ToasterToggle) {
                toast.error(error.response.data.message || 'Failed to start selected accounts');
            }

            console.error('Error starting selected accounts:', error);
        }
    };

    const handleBulkStop = async (stopData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}account/bulkStop`, { accountIds: stopData });
            if (ToasterToggle) { // Assuming you have a mechanism like state or localStorage check for showing toasters
                toast.success(response.data.message);
            }
            // Trigger a re-render or fetch the account list again
            // setrender(prev => !prev);
            fetchAccounts(selectedCategoryId, currentPage)

        } catch (error) {
            if (ToasterToggle) {
                toast.error(error.response.data.message || 'Failed to stop selected accounts');
            }
            console.error('Error stopping accounts:', error);
        }
    };


    const handleCategoryStart = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}category/start/${selectedCategoryId}`);
            toast.success(response.data.message);
            setrender(prev => !prev);
        } catch (error) {
            toast.error('Failed to start category accounts');
        }
    };


    const handleCategorySelect = (categoryId) => {
        setCurrentPage(1)
        setSelectedCategoryId(categoryId);
        setPassSelectedCategoryId(categoryId)
        fetchAccounts(categoryId, 1);

    };

    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('accountColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        localStorage.setItem('accountColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };

    const allAccountStart = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}account/start-all-accounts`);
            toast.success(response.data.message);
            setrender(prev => !prev);
        } catch (error) {
            toast.error('Failed to start category accounts');
        }

    };


    return (
        <div>
            <SearchAccounts setSearchAccounts={setSearchAccounts} />
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ flex: 2, marginRight: "20px" }}>
                    <AccountCategoriesTable categories={categories} onCategorySelect={handleCategorySelect} handleDeleteCategory={ConfirmHandleDeleteCategory} handleEditCategory={handleEditCategory} handleAssignReviewCategory={handleAssignReviewCategory} handleAssignQuestionCategory={handleAssignQuestionCategory} handleAssignSpecificReviewCategory={handleAssignSpecificReviewCategory} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            style={{ width: "130px", height: "50px", padding: "10px", marginTop: "15px", backgroundColor: '#1B3C73', color: "white" }}
                            variant="outlined"
                            size="small"
                            onClick={() => setIsCreateModalOpen(true)}
                        >
                            Category
                        </Button>
                    </div>

                </div>
                <div style={{ flex: 10 }}>
                    {data.length > 0 && accountsData.length > 0 ? (
                        <>
                            <DataTable rows={accountsData} columns={columns} onDeleteSelected={ConfirmHandleDelete} onAssignToProxy={onAssignToProxy} showAssignToCategory={true} showStartAndEndProcess={true} onBulkStart={handleBulkStart} onBulkStop={handleBulkStop} handlePageChange={handlePageChange} currentPage={currentPage} totalPages={totalPages} totalItems={totalItems} selectedCategoryId={selectedCategoryId} onCategoryStart={handleCategoryStart} showCategoryStart={true} columnVisibilityModel={columnVisibilityModel} saveColumnVisibility={saveColumnVisibility} showAllAccountStart={true} allAccountStart={allAccountStart}
                            />
                        </>) : <> </>}
                </div>
            </div>
            {isCreateModalOpen && (
                <CreateAccountCategoryModal
                    open={isCreateModalOpen}
                    onClose={() => setIsCreateModalOpen(false)}
                    fetchCategories={fetchCategories}
                />
            )}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                message="Are you sure you want to delete this record?"
            />

            <ConfirmDeleteModal
                isOpen={isDeleteCategoryModalOpen}
                onClose={handleDeleteCancelCategory}
                onConfirm={handleDeleteConfirmCategory}
                message="Are you sure you want to delete this Category?"
            />

            <ChangeCategoryName
                isOpen={isEditCategoryModalOpen}
                onClose={handleCloseEditCategory}
                category={categoryDetails}
                setrender={setrender}
                render={render}
            />

            {isAssignReviewCategoryModalOpen && (
                <AssignReviewCategoryModalToAccountCategory
                    open={isAssignReviewCategoryModalOpen}
                    onClose={handleCloseAssignReviewCategory}
                    category={selectedCategoryForReview}
                />
            )}

            {isAssignSpecificReviewCategoryModalOpen && (
                <AssignSpecificReviewCategoryModalToAccountCategory
                    open={isAssignSpecificReviewCategoryModalOpen}
                    onClose={handleCloseAssignSpecificReviewCategory}
                    category={selectedCategoryForReview}
                />
            )}


            {isAssignQuestionCategoryModalOpen && (
                <AssignQuestionCategoryModalToAccountCategory
                    open={isAssignQuestionCategoryModalOpen}
                    onClose={handleCloseAssignQuestionCategory}
                    category={selectedCategoryForQuestion}
                />
            )}
        </div>
    );
};

export default AccountCategoriesMain;

